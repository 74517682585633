import * as React from 'react';

import ContactUsPage from '@lce/gatsby-theme-international/src/pages/contact-us';
import { IDefaultPageProps } from '@lce/intl-types/src//IDefaultPageProps';

const Surcursales: React.FC<IDefaultPageProps> = props => (
  <ContactUsPage {...props} />
);

export default Surcursales;
